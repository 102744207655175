import React from 'react';
import {faqPage,faqPageHeader ,faqContainer, faqSection, faqHeader, faqText} from '../styles/layout/faq/index.module.scss';
import { useStaticQuery, graphql } from "gatsby"

const Faq = ()=>{


    const {allContentfulFaq:{edges}} = useStaticQuery(graphql`
    query FAQ {
        allContentfulFaq(filter: {node_locale: {eq: "en-US"}}) {
              edges {
                  node {
              order
              answerShort{
              answerShort}
              question
            }
          }
        }
      }
      
  `)

    return(
        <div className={faqPage}>
            <h2 className={faqPageHeader}>Faq</h2>
        <div className={faqContainer}>
            {edges.sort((a: {node:{order:number}},b:{node:{order:number}})=> a.node.order-b.node.order).map(({node}: any) => (
                <div key={node.question} className={faqSection}>
                <h4 className={faqHeader}>
                {node.question}
                </h4>
                <p className={faqText}>{node.answerShort.answerShort}</p>
                </div>

            ))}
        </div>
        </div>
    );
};



export default Faq;