import React, {useEffect, useRef, useState} from 'react';
import {frycooks,bg,fryCookHeader,video,fryCookText, mintBtn, mintAdopt,arrow,errorText,errorBtn, btnDisabled ,incrementBtn, arrowContainer, count, mint, remaining, featuresContainer, featuresElement,featuresText,featuresSvgContainer, etherscan} from '../styles/layout/frycooks/index.module.scss'
import vid from '../images/SB_PREVROT.mp4';
import ArrowRight from '../components/base/svg/arrowRight';
import ArrowLeft from '../components/base/svg/arrowLeft';
import CopyrightBurger from '../components/base/svg/copy';
import BreakRoom from '../components/base/svg/breakroom';
import AirDrop from '../components/base/svg/airdrop';
import Etherscan from '../components/base/svg/etherscan';
import ProgressBar from '../components/base/svg/progressBar';
import Spinner from '../components/base/svg/spinner';
import FaqPage from './faq';
import {COOKS_ABI, COOKS_ADDRESS} from '../constants/contractAddr';

declare global {
    interface Window { 
    Web3: any;
    ethereum: any
    web3: any
    }
  }

  const totalInCollection = 11111;
  


const FryCooks = ()=> {

  const WalletErrors = {
    NO_WEB3: "Error loading Web3",
    NETWORK_ERROR: "Network error try again",
    METAMASK_CONNECT_ERROR: "Error connecting to Metamask",
    NO_WALLET_AVAILABLE: "No wallet available",
    UNKNOWN_ERROR: "Unknown error refresh and try again",
    PROVIDER_NO_CHAIN_CONNECT: "Web3 provider not connected to chain",
    ADOPT_ERROR: "Mint error verify wallet balance & Metamask version is at least 10.1 if using a hardware wallet",
    NO_MAINNET: "Not connected to Mainnet"
};

const ADOPT_STATE = {
  ADOPTING: "adopting",
  ADOPTED: "adopted",
}
   
      interface walletState {
        address: string | null;
        network: string | null;
        errorMessage: string | null;
        jabbas: any;
        web3Loaded: boolean;
        totalSupply: number | null;
        readyToPurchase: boolean;
        adoptState: string|null;
      }
      
      
      const initialWalletState = {
          address: null,
          network: null,
          errorMessage: null,
          jabbas: [],
          web3Loaded: false,
          totalSupply: null,
          readyToPurchase: false,
          adoptState: null,
        }
      

    const [mintCount, setMintCount] = useState(1);

    const [walletState, setWalletState] = useState<walletState>(initialWalletState);


    const decrementMintCount = ()=> {

        if(mintCount >1){
            setMintCount(mintCount-1)
        }
        else{
            setMintCount(1);
        }
    };

    const incrementMintCount = ()=> {
        if(mintCount <19){
            setMintCount(mintCount+1)
        }
        else{
            setMintCount(20);
        }
    };

    const videoRef = useRef<HTMLVideoElement>(null);


    const requireWeb3 = () => {

      return new Promise<void>((resolve, reject)=> {
        if('web3' in window && 'ethereum' in window){
          if(window.ethereum.isConnected()){
            resolve();
          }else {
            reject(WalletErrors.PROVIDER_NO_CHAIN_CONNECT)
          }
      }else{
          reject(WalletErrors.NO_WEB3);
      }
      })
    };

    const  getNetwork = () => {
        return new Promise<string>((resolve, reject) => {
         window.ethereum.request({ method: 'eth_chainId' }) .then((response:string) => {

            let networkString = "";

            switch(response){
              case "0x1": {networkString="Mainnet"; break;}
              case "0x3": {networkString="Ropsten Test Network"; break;}
              case "0x4": {networkString="Rinkeby Test Network"; break;}
              case "0x5": {networkString="Goerli Test Network"; break;}
              case "0x2a": {networkString="Kovan Test Network"; break;}
              default: {networkString=""; break;}
            }
            setWalletState(prevState => ({...prevState, network:networkString}))
            
            if(networkString !="Mainnet"){
              reject(WalletErrors.NO_MAINNET)
            }

            resolve(networkString)}).catch(()=>{
                        reject(WalletErrors.NETWORK_ERROR);
            })
        })
      }
    

    const connectMetaMask =  (network:string)=> {
        return new Promise<string>((resolve, reject)=>{
        if (window.ethereum) {
           window.ethereum.request({ method: 'eth_requestAccounts' }).then(()=>{
            if(window.ethereum.selectedAddress){
              resolve(network);
            }else{
              reject(WalletErrors.NO_WALLET_AVAILABLE);
            }
           }).catch(()=>{
             reject(WalletErrors.METAMASK_CONNECT_ERROR);
           })
        } else {
          reject(WalletErrors.NO_WALLET_AVAILABLE);
        }
        });      
    }

 
    const updateWalletInfo = (network:string) => {
      return new Promise<string>((resolve,reject)=>{
        if(window.ethereum.selectedAddress){
            setWalletState( prevState =>({...prevState,address: window.ethereum.selectedAddress, readyToPurchase:true}))
        } else {
          reject(WalletErrors.NO_WALLET_AVAILABLE)
        }
        resolve(network);
      });
    };


    const play = ()=> {
      videoRef.current?.play();
    };


    const loadUser =()=>{
      requireWeb3()
      .then(getNetwork)
      .then(connectMetaMask)
      .then(updateWalletInfo)
      .then(()=>{getTotalRemaining()})
      .catch((e)=> {setWalletState(prevState => ({...prevState, errorMessage: e, readyToPurchase:false}))})
};

    useEffect(()=>{
      const loadFn = () => {
        setWalletState(prevState => ({...prevState, web3Loaded: true}))
       }
       if('web3' in window && 'ethereum' in window){
        loadFn();
      }else{
        const script = document.getElementById("web3")!;
        script.addEventListener('load',loadFn)
      }
    },[]);

    useEffect(()=>{
      if (walletState.web3Loaded && window.ethereum) {
            if(window.Web3 && window.Web3.givenProvider){
            window.web3 = new window.Web3(window.Web3.givenProvider)
            loadUser();
      
            window.ethereum.on('accountsChanged', ()=> {
              console.log("ACCOUNT CHANGED")
              setWalletState(prevState=>({...prevState, errorMessage:null}))
             loadUser();
            })

            window.ethereum.on('chainChanged', ()=> {
              console.log("CHAIN CHANGED")
              setWalletState(prevState=>({...prevState, errorMessage:null}))
             loadUser();
            })
          }else{
            setWalletState(prevState =>({...prevState, errorMessage:WalletErrors.NO_WEB3}))
          }
      }
    },[walletState.web3Loaded]);


    const getTotalRemaining= async () => {

      try{
        const fryCookContract = new window.web3.eth.Contract(COOKS_ABI, COOKS_ADDRESS)
        var totalSupply = await fryCookContract.methods.totalSupply().call()
        setWalletState(prevState => ({...prevState, totalSupply}))
      }catch(e){
        console.log(e)
      }

    }

    const resetSpinner = ()=>{
      setWalletState(prevState =>({...prevState, adoptState:null}))
    };

    const purchaseNFT = async ()=>{
      try{
        setWalletState(prevstate =>({...prevstate, errorMessage:null, adoptState:ADOPT_STATE.ADOPTING}))
        const fryCookContract = new window.web3.eth.Contract(COOKS_ABI, COOKS_ADDRESS)
        const price = 100000000000000000;
        const totalPrice = price * mintCount
  
        const transactionParameters = {
          to: COOKS_ADDRESS,
          from: window.ethereum.selectedAddress,
          value: totalPrice.toString() ,
        };
        await fryCookContract.methods.fryCOOK(mintCount).send(transactionParameters)
       getTotalRemaining();
       setWalletState(prevstate =>({...prevstate, errorMessage:null, adoptState:ADOPT_STATE.ADOPTED}))
       
      }catch(e:any){
        if(e.code && e.code ==4001){
          setWalletState(prevState => ({...prevState, adoptState:null}))
        }else{
          setWalletState(prevState => ({...prevState, errorMessage: WalletErrors.ADOPT_ERROR, adoptState:null}))
        }
      }
    }


    useEffect(()=>{
       play();
    },[]);


    return (
        <>
        <div className={bg}>
        <video
                 ref={videoRef}
                 className={video}
                 loop
                 muted={true}
                 playsInline={true}
               >
                <source src={vid} type="video/mp4" />
               </video>         </div>
        <div className={frycooks}>
            <h2 className={fryCookHeader}>Frycooks</h2>
            <p className={fryCookText}>ALL 11,111 FRYCOOKS ARE DISTRIBUTED RANDOMLY AT TIME OF MINTING AT A FIXED PRICE OF 0.1ETH EACH. USE THE ARROWS TO MINT UP TO 20 AT A TIME.</p>
            <div className={`${incrementBtn} ${!walletState.readyToPurchase ? btnDisabled : ""}`}>
                <button disabled={!walletState.readyToPurchase || !!walletState.adoptState} onClick={decrementMintCount} className={arrowContainer}>
                <ArrowLeft className={arrow}/>
                </button>
                <p className={count}>{mintCount}</p>
                <button disabled={!walletState.readyToPurchase||!!walletState.adoptState} onClick={incrementMintCount} className={arrowContainer}>
                <ArrowRight className={arrow}/>
                </button>
            </div>
            <button disabled={!walletState.readyToPurchase || !!walletState.adoptState} onClick={purchaseNFT}  className={`${incrementBtn} ${mintBtn} ${!walletState.readyToPurchase ? btnDisabled : ""}`}>
                <p className={`${mint} ${!!walletState.adoptState ? mintAdopt : ""}`}>Mint!</p>
                <Spinner resetSpinner={resetSpinner} adoptState={walletState.adoptState} />
            </button>
            {/* <button onClick={()=>{setWalletState(prevState =>({...prevState,adoptState:ADOPT_STATE.ADOPTING}))}}>Start Adopt</button>
            <button onClick={()=>{setWalletState(prevState =>({...prevState,adoptState:ADOPT_STATE.ADOPTED}))}}>Stop Adopt</button> */}
            {walletState.errorMessage &&
              <>  
                <p className={errorText}>{walletState.errorMessage}</p>
                <p className={errorText}>Verfify your wallet settings and click <span><button onClick={loadUser} className={errorBtn}>here</button></span> to reconnect</p>
          
              </>}
       
            <div className={remaining}>
              {
                walletState.totalSupply && 
                <ProgressBar remaining={walletState.totalSupply} totalSupply={totalInCollection} progress={walletState.totalSupply/totalInCollection}/>
              }
            </div>
            <div className={featuresContainer}>
                <div className={featuresElement}>
                    <div className={featuresSvgContainer}>
                    <CopyrightBurger className={etherscan}/>
                    </div>
                    <p className={featuresText}>RIGHTS TO COMMERCIAL USE</p>
                </div>

                <div className={featuresElement}>
                    <div className={featuresSvgContainer}>
                    <BreakRoom className={etherscan}/>
                    </div>
                    <p className={featuresText}>Access to the breakroom</p>
                </div>

                <div className={featuresElement}>
                    <div className={featuresSvgContainer}>
                    <AirDrop className={etherscan}/>
                    </div>
                    <p className={featuresText}>AirDrop Inclusion</p>
                </div>

                <div className={featuresElement}>
                    <div className={featuresSvgContainer}>
                    <Etherscan className={etherscan}/>
                    </div>
                    <p className={featuresText}>Dedicated Smart Contract</p>
                </div>
            </div>
        </div>
        <FaqPage/>
        </>
    );
};


export default FryCooks;