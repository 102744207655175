import React, { SVGAttributes } from 'react'


const ArrowRight = ({className}: SVGAttributes<SVGElement>)=> {

    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.62 223.62"><g><g><polygon className={className}  points="222.5 111.81 0.5 0.81 0.5 222.81 222.5 111.81"/></g></g></svg>
    );
};


export default ArrowRight;