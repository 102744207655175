import React, { SVGAttributes } from 'react'


const ArrowLeft = ({className}: SVGAttributes<SVGElement>)=> {

    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.62 223.62"><g><g><polygon className={className} points="1.12 111.81 223.12 222.81 223.12 0.81 1.12 111.81"/></g></g></svg>
    );
};

export default ArrowLeft

